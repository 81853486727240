import { Injectable, inject } from '@angular/core';
import { AES, enc } from 'crypto-js';
import {
  Firestore,
  collection,
  getDocs,
  query,
  where,
} from '@angular/fire/firestore';

import { Commodities } from '../interfaces/base-interface';
import { Stockpoints, StockpointServices } from '../interfaces/stockpoint-interface';
import { LedgerEntry } from '../interfaces/orders-interface'; 
import { Badges } from '../interfaces/users-interface';
import { consumerPollProducersForChange } from '@angular/core/primitives/signals';

// import { Barangays } from '../interfaces/refbrgy';
// import { Municipalities } from '../interfaces/refcitymun';
// import { Provinces } from '../interfaces/refprovince';
// import { Regions } from '../interfaces/refregion';

@Injectable({
  providedIn: 'root',
})
export class SystemService {
  cryptKey = 'kalakalan';
  private firestore: Firestore = inject(Firestore); // inject Cloud Firestore

  // list of lookups
  stockpoints: Stockpoints[] = [];
  commodities: Commodities[] = [];
  badges: Badges[] = [];

  public conversions: any = {};
  // minDeliveryDays
  minDeliveryDays: number = 2;
  minTradeDays: number = 0;

  public calloutMessage: string = '';
  public calloutVisible: boolean = false;
  public calloutColor: string = 'info';

  constructor() {}

  formatNumber(value: number) {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  formatInteger(value: number) {
    return value.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
  formatCurrency(value: number) {
    if (value === undefined) value = 0;
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'PHP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  formatShortDay(thisDate: Date) {
    const sDate = new Intl.DateTimeFormat('en-PH', {
      month: 'short',
      day: 'numeric',
    });
    return sDate.format(thisDate);
  }

  // LOCAL STORAGE Functions
  public saveData(key: string, value: string) {
    localStorage.setItem(key, this.encrypt(value));
  }
  public getData(key: string) {
    let data = localStorage.getItem(key) || '';
    return this.decrypt(data);
  }
  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  private encrypt(txt: string): string {
    return AES.encrypt(txt, this.cryptKey).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return AES.decrypt(txtToDecrypt, this.cryptKey).toString(enc.Utf8);
  }

  // Lookup functions

  public checkLookups() {
    // when checking each lookup, follow this logic
    // 1. first check if the lookup is already in local storage
    // 2. If not in local storage proceed with initialization
    // 3. If lookup is in local storage,
    //       then check if there have been changes based on timestamp
    // 4. If there are no changes then stop
    // 5. If there have been changes then perform initialization again

    let storedStockpoints = this.getData('stockpoints');
    if (storedStockpoints !== '') {
      this.stockpoints = JSON.parse(storedStockpoints);
      console.log('System: got stockpoints', this.stockpoints);
      // check for updates
      // this.updateStockpoints();
    } else {
      console.log('System: no stored stockpoints');
      this.initializeStockpoints();
    }

    let storedCommodities = this.getData('commodities');
    if (storedCommodities !== '') {
      this.commodities = JSON.parse(storedCommodities);
      console.log('System: got commodities', this.commodities);
      // check for updates
      // this.updateCommodities();
    } else {
      console.log('System: no stored commodities');
      this.initializeCommodities();
    }

    let storedBadges = this.getData('badges');
    if (storedBadges !== '') {
      this.badges = JSON.parse(storedBadges);
      console.log('System: got badges', this.badges);
    } else {
      console.log('System: no stored badges');
      this.getAllBadges();
    }
  }

  public refreshLookups() {
    this.initializeCommodities();
    this.initializeStockpoints();
    this.getAllBadges();
  }
  public blankStockpoint() {
    return {
      stockpointID: '',
      name: '',
      description: '',
      shortname: '',
      phone: '',
      address: {
        street: '',
        city: '',
        barangay: '',
        province: '',
        zipcode: '',
      },
      coords:{
        latitude:14.653493,
        longitude:121.046258
      },
      services: [],
      commodities: [],
    };
  }

  private initializeStockpoints() {
    // Initialization steps are as follows
    // 1. Get all the Stockpoints
    // 2. Save to local storage
    this.getAllStockpoints().then((result) => {
      console.log('System: got result', result);
      if (result.length > 0) {
        this.stockpoints = result;
        console.log('saving stockpoints to local storage');
        this.saveData('stockpoints', JSON.stringify(this.stockpoints));
      }
    });
  }

  private async getAllStockpoints() {
    const allStockpoints: Stockpoints[] = [];
    const querySnapshot = await getDocs(
      collection(this.firestore, 'system/lookups/stockpoints')
    );
    querySnapshot.forEach((doc: any) => {
      let stockpoint = doc.data();
      // this.getServiceCalculations(stockpoint);
      allStockpoints.push(stockpoint);
    });

    console.log('System: got stockpoints from firestore', allStockpoints);
    return allStockpoints;
  }

  public getServiceCalculations(stockpoint: Stockpoints) {
    let services = stockpoint.services;
    services.forEach((service) => {
      if (typeof service.costFromUOM !== 'function') {
        const costFromUOM = new Function(...service.costFromUOM);
        service.costFromUOM = costFromUOM;
      }
      if (typeof service.taxes !== 'function') {
        const taxes = new Function(...service.taxes);
        service.taxes = taxes;
      }
    });
    console.log('getServiceCalculations: new Stockpoint', stockpoint);
  }

  public getServiceCharges(
    services: any[] = [],
    orderQuantity: number,
    creditAcct: string,
    debitAcct: string
  ) {
    let serviceCharges: LedgerEntry[] = [];
    console.log('getServiceCharges: services', services, orderQuantity);
    services.forEach((service) => {
      let amount = Math.floor(service.costFromUOM(orderQuantity) * 100) / 100;
      let taxes = Math.floor(service.taxes(amount) * 100) / 100;
      let particulars =
        service.name +
        ' for ' +
        orderQuantity +
        ' ' +
        service.uom +
        's of ' +
        service.commodityCode;
      serviceCharges.push({
        creditAcct: creditAcct,
        debitAcct: debitAcct,
        amount: amount,
        particulars: particulars,
        tax: taxes,
        status: 'draft',
      });
    });
    console.log('getServiceCharges: serviceCharges', serviceCharges);
    return serviceCharges;
  }

  private initializeCommodities() {
    // Initialization steps are as follows
    // 1. Get all the Commodities
    // 2. Save to local storage
    this.getAllCommodities().then((result: any[]) => {
      console.log('System: got stockpoints', result);
      if (result.length > 0) {
        this.commodities = result;
        console.log('saving commodities to local storage');
        result.forEach((commodity) => {
          this.getAllSubCommodities(commodity).then((subresult: any[]) => {
            if (subresult.length > 0) {
              Object.assign(commodity, { subCommodities: subresult });
              console.log(
                'System: initializeCommodities: Got subcommodities for :',
                commodity
              );
              this.saveData('commodities', JSON.stringify(this.commodities));
            }
          });
        });
        this.saveData('commodities', JSON.stringify(this.commodities));
      }
    });
    this.getAllStockpoints().then((result) => {
      console.log('System: got stockpoints', result);
      if (result.length > 0) {
        this.stockpoints = result;
        console.log('saving stockpoints to local storage');
        this.saveData('stockpoints', JSON.stringify(this.stockpoints));
      }
    });
  }

  /**
   * Firebase Firestore call to getAllCommodities
   * @returns A
   */
  private async getAllCommodities() {
    const allCommodities: Commodities[] = [];
    const commoditiesRef = collection(
      this.firestore,
      'system/lookups/commodities'
    );
    const q = query(
      commoditiesRef,
      where('status', 'in', ['active', 'upcoming'])
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc: any) => allCommodities.push(doc.data()));
    console.log('System: got commodities from firestore', allCommodities);
    allCommodities.sort((a, b) => {
      return a['sortOrder'] > b['sortOrder'] ? 1 : -1;
    });
    return allCommodities;
  }

  private async getAllSubCommodities(commodity: Commodities) {
    const allSubCommodities: Commodities[] = [];
    const querySnapshot = await getDocs(
      collection(
        this.firestore,
        'system/lookups/commodities/' +
          commodity.commodityID +
          '/subCommodities'
      )
    );
    querySnapshot.forEach((doc: any) =>
      allSubCommodities.push(
        Object.assign(doc.data(), { conversions: commodity.conversions })
      )
    );
    console.log('System: got subcommodities from firestore', allSubCommodities);
    return allSubCommodities;
  }

  blankCommodity() {
    return {
      commodityID: '',
      name: '',
      code: '',
      description: '',
      uom: '',
      moq: 1,
      testingOptions: [],
      acceptanceOptions: [],
      conversions: [],
      sortOrder: 0,
      status: '',
      imageURL: '',
    };
  }

  getConversions(commodity: Commodities) {
    const conversions = commodity.conversions;
    if (conversions === undefined) return;
    let parsedConversions: any = {};
    conversions.forEach((conversion) => {
      console.log('conversion', conversion);
      let fromUOM = new Function(...conversion.fromUOM);
      let toUOM = new Function(...conversion.toUOM);
      parsedConversions[conversion.unit] = {
        fromUOM: fromUOM,
        toUOM: toUOM,
        sortOrder: conversion.sortOrder,
      };
    });
    // save the conversions to system service
    this.conversions = Object.assign({}, parsedConversions);
    return parsedConversions;
  }

  /**
   * Get all badges and save to local storage
   * @returns badges
   */
  public async getAllBadges() {
    const allBadges: Badges[] = [];
    const badgesRef = collection(this.firestore, 'system/lookups/badges');
    const querySnapshot = await getDocs(badgesRef);
    querySnapshot.forEach((doc: any) => allBadges.push(doc.data()));
    console.log('System: got badges from firestore', allBadges);
    this.saveData('badges', JSON.stringify(allBadges));
    this.badges = allBadges;
    return allBadges;
  }

  public doCallout(message: string, color: string='info', duration:number = 2000) {
    this.calloutMessage = message;
    this.calloutColor = color;
    this.calloutVisible = true;
    console.log("calloutVisible", this.calloutVisible);
    setTimeout(() => {
      this.calloutVisible = false;  
      console.log("calloutVisible", this.calloutVisible);
    }, duration)
  }

  // address Lookups

  // getBarangays (){
  //   return Barangays;
  // }
  // getMunicipalities(){
  //   return Municipalities;
  // }
  // getProvinces(){
  //   return Provinces;
  // }
  // getRegions (){
  //   return Regions;
  // }
}
